import * as Styled from 'components/ToolkitV2/CardV2/enhancements/WithImageOverlay/WithImageOverlay.styled';

import type { WithImageOverlayProps } from 'components/ToolkitV2/CardV2/enhancements/WithImageOverlay/WithImageOverlay.typed';

function WithImageOverlay(props: WithImageOverlayProps) {
  const { children, bottomLeftSlot, bottomRightSlot, topRightSlot } = props;

  return (
    <Styled.ImageContainer>
      {children}
      {topRightSlot && (
        <Styled.TopRightSlot>{topRightSlot}</Styled.TopRightSlot>
      )}
      {bottomLeftSlot && (
        <Styled.BottomLeftSlot>{bottomLeftSlot}</Styled.BottomLeftSlot>
      )}
      {bottomRightSlot && (
        <Styled.BottomRightSlot>{bottomRightSlot}</Styled.BottomRightSlot>
      )}
    </Styled.ImageContainer>
  );
}

export { WithImageOverlay };
