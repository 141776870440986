import { CheckmarkCircleOutlineIc, RemoveIc } from '@dsch/dd-icons';
import { useTheme } from 'styled-components';
import { CDN_STATIC_ASSETS } from 'utils';

import { BaseModal } from 'components/Toolkit/Modal/BaseModal';
import { Button } from 'components/Toolkit/Button';
import { Link } from 'components/Toolkit/Button/Link';
import { InfoTitle } from 'components/Toolkit/InfoTitle/InfoTitle';
import { Notification } from 'components/Toolkit/Notification/Notification';
import { Select } from 'components/Toolkit/Inputs/Select';

import { useValuationModal } from 'components/HomePage/components/ValuationModal/ValuationModal.hook';
import { useHomePageContext } from 'contexts/HomePageContext';
import { DetailList } from 'components/Toolkit/DetailList/DetailList';

import * as Styled from 'components/HomePage/components/ValuationModal/ValuationModal.styled';

const ValuationSuccess = () => {
  const theme = useTheme();

  const {
    renewalOptions,
    vehicleInfoTitle,
    similarCarsLink,
    valuationRange,
    handleRenewalMonthChange,
    handleSaveProfile,
  } = useValuationModal();
  return (
    <Styled.Content>
      <InfoTitle
        title="How much is my car worth?"
        listItems={[
          <Styled.SubtitleContainer key={'valuation-header-subitem-0'}>
            <Styled.Subtitle>In partnership with </Styled.Subtitle>
            <img
              src={`${CDN_STATIC_ASSETS}/images/thirdparty/AllianzSecondary.svg`}
              alt="Allianz Logo"
            />
          </Styled.SubtitleContainer>,
        ]}
      />

      <Styled.Space />

      <InfoTitle
        icon={
          <CheckmarkCircleOutlineIc
            color={theme.colors.GREEN}
            width="40px"
            height="40px"
          />
        }
        title={vehicleInfoTitle.title}
        listItems={vehicleInfoTitle.subtitle}
        variant="SMALL"
      />

      <Styled.Space />

      <Styled.VehicleValuationPanel>
        <InfoTitle
          title={valuationRange}
          listItems={['Your car is worth']}
          flip
        />

        <Link
          href={similarCarsLink}
          ofType="PRIMARY"
          target="_blank"
          fontSize="S12"
        >
          {' '}
          View similar cars
        </Link>
      </Styled.VehicleValuationPanel>

      <Styled.Space />

      <hr />

      <Styled.Space />

      <Styled.RenewalPanel>
        <Styled.RenewalInfoTitle
          title={'Get the latest Allianz car insurance offers on DoneDeal'}
          listItems={['At a time that suits you']}
        />
        <Select
          id="renewal_month"
          name="renewal_month"
          label={'Enter your renewal month'}
          options={renewalOptions}
          onChange={handleRenewalMonthChange}
          willUseSubText={true}
        />
      </Styled.RenewalPanel>

      <Styled.Space />

      <Button ofType="PRIMARY" size="LARGE" onClick={handleSaveProfile}>
        Save to profile
      </Button>
    </Styled.Content>
  );
};

const ValuationError = () => {
  const theme = useTheme();

  const failedValuationReasons = [
    'You entered an invalid registration number',
    'Your car does not have an Irish registration',
    'Your car is greater than 10 years old',
    "There's not enough data to value your car",
    'Your vehicle is not a car',
    "You're not logged-in to DoneDeal",
  ];

  const { setIsValuationModalOpen } = useHomePageContext();

  return (
    <Styled.Content>
      <InfoTitle
        title="How much is my car worth?"
        listItems={[
          <Styled.Subtitle key={'valuation-header-subitem-0'}>
            <p>In partnership with </p>
            <img
              src={`${CDN_STATIC_ASSETS}/images/thirdparty/AllianzSecondary.svg`}
              alt="Allianz Logo"
            />
          </Styled.Subtitle>,
        ]}
      />

      <Styled.Space />

      <Notification intent="ERROR">
        We couldn't find your car from its registration.
      </Notification>

      <Styled.Space />

      <div>
        <Styled.DetailListHeading>
          This can happen for a number of reasons:
        </Styled.DetailListHeading>
        <DetailList
          singleColumnOnDesktop={true}
          list={failedValuationReasons.map((assumption) => ({
            title: assumption,
            icon: <RemoveIc width="16" height="16" color={theme.colors.GREY} />,
          }))}
        />
      </div>

      <Styled.Space />

      <Button
        ofType="PRIMARY"
        size="LARGE"
        onClick={() => setIsValuationModalOpen(false)}
      >
        Try again
      </Button>
    </Styled.Content>
  );
};

const ValuationModal = () => {
  const {
    isValuationModalOpen,
    setIsValuationModalOpen,
    homePageValuationModalData,
  } = useHomePageContext();

  return (
    <BaseModal
      askToClose={() => setIsValuationModalOpen(false)}
      isOpen={isValuationModalOpen}
      variant="WIDE"
      testId="homepage-valuation-modal"
    >
      {homePageValuationModalData ? <ValuationSuccess /> : <ValuationError />}
    </BaseModal>
  );
};

export { ValuationModal };
