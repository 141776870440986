import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { formatAuthorizationHeader } from 'helpers/auth';
import type { useHomePageValuationLookupProps } from 'features/landing/HomePageValuationLookup/HomePageValuationLookup.typed';
import { adWriteApi } from 'api/adWriteApi';
import { carValuationApi } from 'api/carValuationApi';
import type { ValuationLookupFormValues } from 'features/landing/HomePageValuationLookup/HomePageValuationLookup.typed';
import { isOk } from 'domains/Result';
import { ok } from 'domains/Result';
import { useHomePageContext } from 'contexts/HomePageContext';
import { convertMilesToKm } from 'helpers/formatting';
import { useAuthOptions } from 'hooks/useAuthOptions';
import { useBrowserStorage } from 'hooks/UseBrowserStorage';
import { useQueryParams } from 'hooks/UseQueryParams';
import { useUserContext } from 'contexts/UserContext';
import { MILEAGE_UNIT } from 'types/index';

import { HOMEPAGE_VALUATION_LOOKUP } from 'features/landing/HomePageValuationLookup/HomePageValuationLookup.typed';

const useHomePageValuationLookup = (props: useHomePageValuationLookupProps) => {
  const { accessToken } = props;
  const source = 'HOMEPAGE';
  const { setIsValuationModalOpen, setHomePageValuationModalData } =
    useHomePageContext();

  const { query, asPath } = useRouter();
  const { user } = useUserContext();

  const { status, handleLogin } = useAuthOptions();
  const { checkIfQueryIsPresent } = useQueryParams();
  const { setLocalStorageItem, getLocalStorageItem, removeLocalStorageItem } =
    useBrowserStorage();

  const MODAL_QUERY_KEY = 'modal';
  const MODAL_QUERY_VALUE = 'homepage-valuation';
  const isHomePageValuationModalQueryPresent = checkIfQueryIsPresent(
    MODAL_QUERY_KEY,
    MODAL_QUERY_VALUE,
  );
  const callbackUrl = isHomePageValuationModalQueryPresent
    ? asPath
    : `${asPath}&modal=${MODAL_QUERY_VALUE}`;

  const openModal = () => setIsValuationModalOpen(true);
  const closeModal = () => setIsValuationModalOpen(false);

  // // TODO: It would be good to move the valuation work accross the app into a domain / repository.

  const fetchPrivateCarValuation = async (
    registration: string,
    mileage: string,
  ) => {
    const { data } = await carValuationApi.getCarValuation(
      registration,
      mileage,
      source,
      formatAuthorizationHeader(accessToken),
    );

    return ok(data);
  };

  const fetchPrivateVehicleLookup = async (registration: string) => {
    const { data } = await adWriteApi.refinedVehicleRegistrationNumberLookup(
      registration,
      'cars',
      formatAuthorizationHeader(accessToken),
    );
    return ok(data);
  };

  const handleValuationLookup = async (
    registration: string,
    mileage: string,
  ) => {
    const carValuation = await fetchPrivateCarValuation(registration, mileage);
    const vehicleLookup = await fetchPrivateVehicleLookup(registration);

    if (isOk(carValuation) && isOk(vehicleLookup)) {
      const attributes = vehicleLookup.ok.metaInfoBlock.items;

      const make =
        attributes?.find((item) => item.name === 'make')?.displayValue || '';
      const model =
        attributes?.find((item) => item.name === 'model')?.displayValue || '';
      const year =
        attributes?.find((item) => item.name === 'year')?.displayValue || '';

      const bodyType =
        attributes?.find((item) => item.name === 'bodyType')?.displayValue ||
        '';
      const fuelType =
        attributes?.find((item) => item.name === 'fuelType')?.displayValue ||
        '';
      const transmission =
        attributes?.find((item) => item.name === 'transmission')
          ?.displayValue || '';

      const trim =
        vehicleLookup.ok.hiddenFields.items?.find(
          (item) => item.name === 'trim',
        )?.value || '';

      const { min, max } = carValuation.ok.privateMarketOfferRange;

      return {
        make,
        model,
        year,
        trim,
        bodyType,
        fuelType,
        transmission,
        valuation: {
          min,
          max,
        },
      };
    } else {
      throw new Error('Something went wrong');
    }
  };

  const deleteValuationDataFromLocalStorage = () => {
    const valuationData: ValuationLookupFormValues = getLocalStorageItem(
      HOMEPAGE_VALUATION_LOOKUP.KEY,
    );

    if (valuationData) {
      removeLocalStorageItem(HOMEPAGE_VALUATION_LOOKUP.KEY);
    }
  };

  const onSubmit = async (values: ValuationLookupFormValues) => {
    const { registration, mileage, mileageType } = values;

    if (status === 'unauthenticated') {
      setLocalStorageItem(HOMEPAGE_VALUATION_LOOKUP.KEY, {
        registration,
        mileage,
        mileageType,
        expiryDate: (Date.now() + 12096e5).toString(),
      });
      handleLogin(callbackUrl);
      return;
    }

    try {
      const mileageValue =
        mileageType === MILEAGE_UNIT.MILES
          ? convertMilesToKm(Number(mileage))
          : mileage;

      const lookupValues = await handleValuationLookup(
        registration,
        mileageValue,
      );

      setHomePageValuationModalData({
        ...values,
        ...lookupValues,
      });

      openModal();
      deleteValuationDataFromLocalStorage();
    } catch (error) {
      setHomePageValuationModalData(undefined);
      openModal();
    }
  };

  useEffect(() => {
    if (isHomePageValuationModalQueryPresent) {
      const valuationData: ValuationLookupFormValues = getLocalStorageItem(
        HOMEPAGE_VALUATION_LOOKUP.KEY,
      );

      if (valuationData) {
        const { registration, mileage, mileageType } = valuationData;
        onSubmit({ registration, mileage, mileageType });
      }
    } else {
      closeModal();
    }
    // TODO Clean up this effect's dependencies. We're disabling this lint error for now so we can
    // clean up the lint logs. Ideally we should rewrite this code to be less error prone and trust
    // the lint rule's judgement.
    // https://distilledsch.tpondemand.com/RestUI/Board.aspx#page=userstory/98606
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, status, user]);

  return { onSubmit, isHomePageValuationModalQueryPresent };
};

export { useHomePageValuationLookup };
