import styled from 'styled-components';

const ImageContainer = styled.div`
  position: relative;
`;

const BottomLeftSlot = styled.div`
  position: absolute;
  left: ${({ theme }) => theme.spacing.S8};
  bottom: ${({ theme }) => theme.spacing.S8};
  display: flex;
  gap: ${({ theme }) => theme.spacing.S8};
`;

const BottomRightSlot = styled.div`
  position: absolute;
  right: ${({ theme }) => theme.spacing.S8};
  bottom: ${({ theme }) => theme.spacing.S8};
`;

const TopRightSlot = styled.div`
  position: absolute;
  right: ${({ theme }) => theme.spacing.S8};
  top: ${({ theme }) => theme.spacing.S8};
`;

export { ImageContainer, BottomLeftSlot, BottomRightSlot, TopRightSlot };
