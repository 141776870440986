import { useEffect, useState } from 'react';
import { DFPManager } from '@dsch/react-dfp';

import type { SiteFocus } from 'components/HomePage/types';
import { useOnUpdateOnly } from 'hooks/UseOnUpdateOnly';
import { adViewApi } from 'api/adViewApi';
import { fireToast } from 'helpers/Toasts';
import { HomePageResponse } from 'api/types/adviewApiTypes';
import {
  AdSummary,
  DataTargetingProps,
  LifestyleLinks,
  PromoSlot,
} from 'types';
import { useUserContext } from 'contexts/UserContext';
import { updateSiteFocusCookie } from 'helpers/updateSiteFocusCookie';
import { pushToDataLayer } from 'services/gtmService';
import { rg4js } from 'helpers/raygun';
import { PAGE } from 'helpers/pages';

export type Section = {
  id?: number;
  name: string;
  displayName: string;
  link?: string;
  query?: { [key: string]: string };
};

export type HomePageData = {
  h1: string;
  sections: Section[];
  recentAds: AdSummary[];
  recentAdsUrl: string | null;
  featuredAds: PromoSlot | null;
  lifestyleLinks?: LifestyleLinks[];
};

type UseHomePageProps = {
  init: { siteFocus: SiteFocus; homePageData: HomePageData };
  domain: string;
  setVertical: (siteFocus: SiteFocus) => void;
  dataTargetingProps: DataTargetingProps;
  lifestyleLinks?: LifestyleLinks[];
};

const formatSiteFocus = (siteFocus: SiteFocus) => {
  return siteFocus.charAt(0).toUpperCase() + siteFocus.substring(1);
};

const getDFPSettings = (siteFocus: SiteFocus) => ({
  site: 'donedealclassifieds',
  vertical: [formatSiteFocus(siteFocus)],
});

const updateDFP = (
  siteFocus: SiteFocus,
  dataTargetingProps: DataTargetingProps,
) => {
  DFPManager.setTargetingArguments({
    ...getDFPSettings(siteFocus),
    ...dataTargetingProps?.dfp,
  });
};

const H1 = {
  MOTOR: 'Browse by category',
  MARKETPLACE: 'Buy & Sell Locally in Ireland',
  FARMING: 'Buy & Sell Farm Machinery & Tractors',
};

export const getH1 = (siteFocus: SiteFocus): string => {
  switch (siteFocus) {
    case 'motor':
      return H1.MOTOR;
    case 'marketplace':
      return H1.MARKETPLACE;
    case 'farming':
      return H1.FARMING;
    default:
      return H1.MARKETPLACE;
  }
};

const mapHomePageResponse = (
  siteFocus: SiteFocus,
  response: HomePageResponse,
): HomePageData => ({
  h1: getH1(siteFocus),
  sections: response.sections,
  recentAds: response.recentAds,
  recentAdsUrl: response.recentAdsUrl,
  featuredAds: response.featuredDealer,
  lifestyleLinks: response.lifestyleLinks || [],
});

const useHomePage = (props: UseHomePageProps) => {
  const { init, domain, setVertical, dataTargetingProps } = props;
  const { ddRecommendedUser } = useUserContext();
  const [siteFocus, setSiteFocus] = useState<SiteFocus>(init.siteFocus);
  const [homePageData, setHomePageData] = useState<HomePageData>(
    init.homePageData,
  );
  const updateHomePageData = async () => {
    try {
      const { data } = await adViewApi.getHomePageData(
        siteFocus,
        ddRecommendedUser
          ? {
              dd_recommendation_user: ddRecommendedUser.toString(),
            }
          : undefined,
      );
      setHomePageData(mapHomePageResponse(siteFocus, data));
      setVertical(siteFocus);
      pushToDataLayer(data.dataTargetingProps.gtm);
      updateSiteFocusCookie(siteFocus, domain);
    } catch (error) {
      rg4js('send', {
        error: new Error('Error handling updateHomePageData'),
        tags: [PAGE.HOME],
        customData: {
          message: error.message || 'client_error',
        },
      });
      fireToast({
        type: 'ERROR',
        text: 'Oops! Something went wrong, please try again later',
      });
    }
  };

  useOnUpdateOnly(() => {
    updateHomePageData();
  }, [siteFocus]);

  useEffect(() => {
    updateDFP(siteFocus, dataTargetingProps);
    // TODO: Clean up this effect's dependencies. We're disabling this lint error for now so we can
    // clean up the lint logs. Ideally we should rewrite this code to be less error prone and trust
    // the lint rule's judgement.
    // https://distilledsch.tpondemand.com/RestUI/Board.aspx#page=userstory/98606
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteFocus]);

  return { siteFocus, homePageData, setSiteFocus };
};

export { useHomePage };
