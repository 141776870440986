import { MyVehicleUpdateRequest } from 'api/types/accountsApiTypes';
import { userRepository } from 'repositories/User/UserRepository';
import { formatAuthorizationHeader } from 'helpers/auth';
import { isError } from 'domains/Result';
import { fireToast } from 'helpers/Toasts';
import { useAuthOptions } from 'hooks/useAuthOptions';
import { MILEAGE_UNIT } from 'types';

export type UseMyVehicleUpdateProps = {
  registration: string;
  mileage: string;
  source: string;
};

const useMyVehicleUpdate = (props: UseMyVehicleUpdateProps) => {
  const { registration, mileage, source } = props;
  const { accessToken } = useAuthOptions();

  const updateMyVehicle = async (insuranceRenewal: string) => {
    const myVehicle: MyVehicleUpdateRequest = {
      registrationNumber: registration,
      mileage,
      // Mileage unit is always Kilometers - we cannot pass this as a parameter in the URL
      mileageUnit: MILEAGE_UNIT.KILOMETRES,
      insuranceRenewal,
      source,
    };
    const result = await userRepository.updateMyVehicle(
      myVehicle,
      formatAuthorizationHeader(accessToken),
    );
    if (isError(result)) {
      fireToast({
        type: 'ERROR',
        text: 'Oops! Something went wrong, please try again later',
      });
    }
  };

  return {
    updateMyVehicle,
  };
};

export { useMyVehicleUpdate };
